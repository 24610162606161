import React from 'react';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Container from '@mui/material/Container';

import './styles.css'

import GridViewRoundedIcon from '@mui/icons-material/GridViewRounded';
import SearchRoundedIcon from '@mui/icons-material/SearchRounded';
import NotificationsOutlinedIcon from '@mui/icons-material/NotificationsOutlined';
import AccountCircleRoundedIcon from '@mui/icons-material/AccountCircleRounded';
import MenuRoundedIcon from '@mui/icons-material/MenuRounded';

function BottomHeader() {
    return (
        <AppBar
            position="fixed"
            style={{
                top: 'auto',
                bottom: 0,
                backgroundColor: '#000',
                userSelect: 'none',
                borderTopLeftRadius: 10,
                borderTopRightRadius: 10
            }}
        >
            <Container maxWidth="xl">
                <Toolbar disableGutters>
                    <div className='bottom-header-container'>
                        <section>
                            <GridViewRoundedIcon htmlColor='#fff' fontSize='large' />
                            <label>Feed</label>
                        </section>
                        <section>
                            <SearchRoundedIcon htmlColor='#fff' fontSize='large' />
                            <label>Buscar</label>
                        </section>
                        <section>
                            <NotificationsOutlinedIcon htmlColor='#fff' fontSize='large' />
                            <label>Notificações</label>
                        </section>
                        <section>
                            <AccountCircleRoundedIcon htmlColor='#fff' fontSize='large' />
                            <label>Perfil</label>
                        </section>
                        <section>
                            <MenuRoundedIcon htmlColor='#fff' fontSize='large' />
                            <label>Menu</label>
                        </section>
                    </div>
                </Toolbar>
            </Container>
        </AppBar>
    )
}

export default BottomHeader