import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from 'react-responsive-carousel';

import AddShoppingCartOutlinedIcon from '@mui/icons-material/AddShoppingCartOutlined';
import ArrowBackOutlinedIcon from '@mui/icons-material/ArrowBackOutlined';
import ShoppingCartOutlinedIcon from '@mui/icons-material/ShoppingCartOutlined';

import './styles.css'

function StoreComp(props) {
    const navigate = useNavigate()
    const [productInfo, setProductInfo] = useState(null)
    const [descriptionOption, setDescriptionOption] = useState(['Detalhes', 'Vendedor', 'Cuidados', 'Medidas'])
    const [descriptionSelect, setDescriptionSelect] = useState('Detalhes')
    const [hashtags, setHashtags] = useState([])

    useEffect(() => {
        setProductInfo(props.productInfo)
    }, [props])

    useEffect(() => {
        if (props.productInfo.Tag) {
            const apiItem = props.productInfo.Tag
            const arrayFromApiData = apiItem.split(';').map(item => item.trim());
            setHashtags(arrayFromApiData)
        }
    }, [props.productInfo])

    return (
        <div>
            {productInfo &&
                <>
                    <div className="product-header">
                        <div id="product-header-back" onClick={() => props.selectScreenPress()}>
                            <ArrowBackOutlinedIcon htmlColor="#272727" fontSize="medium" style={{ backgroundColor: '#fff', borderRadius: '100%', padding: 2 }} />
                        </div>

                        <div>
                            <img
                                src={productInfo._Usuario.FotoProfile}
                                style={{ width: 35, height: 35, borderRadius: '100%' }}
                                alt=""
                            />
                            <label>{productInfo._Usuario.Nome}</label>
                        </div>

                        <div id="product-header-cart">
                            <ShoppingCartOutlinedIcon htmlColor="#fff" fontSize="medium" />
                            <label>0</label>
                        </div>
                    </div>

                    <div className="product-carousel">
                        <Carousel
                            infiniteLoop={true}
                            showThumbs={false}
                            showArrows={false}
                            autoPlay
                        >
                            <div>
                                <img src={productInfo.LocalIIS} style={{ width: '100%', height: 400 }} />
                            </div>
                            {productInfo._ImagensComplementares &&
                                productInfo._ImagensComplementares.map((res, index) => (
                                    <div key={index}>
                                        <img alt="" src={res.LocalIIS} style={{ width: '100%', height: 400 }} />
                                    </div>
                                ))
                            }
                        </Carousel>
                    </div>

                    <div className="product-content">
                        <div style={{
                            display: 'flex',
                            flexWrap: 'wrap',
                            width: '100%',
                            gap: 10
                        }}>
                            {hashtags && hashtags.map((res, index) => (
                                <label
                                    key={index}
                                    style={{
                                        backgroundColor: '#000000',
                                        borderRadius: 20,
                                        padding: '5px 10px',
                                        color: '#fff',
                                        fontSize: 14,
                                    }}
                                >
                                    {res}
                                </label>
                            ))}
                        </div>
                        
                        {productInfo?._Usuario?._Endereco[0]?.END_Cidade && productInfo?._Usuario?._Endereco[0]?.END_Estado &&
                            <div id="product-content-location">
                                <label>{`Localidade da Peça: ${productInfo?._Usuario?._Endereco[0]?.END_Cidade}/${productInfo?._Usuario?._Endereco[0]?.END_Estado}`}</label>
                            </div>
                        }

                        <div id="product-content-price">
                            <label id="product-content-price-text">{productInfo?._Etiquetas[0]?.mValor.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}</label>
                            <div id="product-content-price-card">
                                <label>Adicionar</label>
                                <AddShoppingCartOutlinedIcon htmlColor="#fff" fontSize='medium' />
                            </div>
                        </div>

                        <div id="product-content-product-name">
                            <label>{`${productInfo?._Etiquetas[0]?._Peca.Nome} - ${productInfo._Etiquetas[0]._Marca.Nome}`}</label>
                        </div>

                        <div id="product-content-product-tamanho">
                            <label>Tamanho:</label>
                            <div>
                                <label>{`${productInfo?._Etiquetas[0]?.sTamanho}`}</label>
                            </div>
                        </div>

                        <div id="product-content-product-tamanho">
                            <label>Estoque: 1</label>
                        </div>

                        <div className="product-description-container">
                            {descriptionOption.map((res, index) => (
                                <label
                                    id={descriptionSelect === res ? 'product-description-text-active' : 'product-description-text'}
                                    key={index}
                                    onClick={() => setDescriptionSelect(res)}
                                >
                                    {res}
                                </label>
                            ))}
                        </div>
                        {console.log(productInfo)}
                        <div className="product-description-content" >
                            {descriptionSelect === 'Detalhes' &&
                                <div className="product-description-detalhes">
                                    <label>{`${productInfo?.Descricao}`}</label>
                                    {productInfo?._Etiquetas[0]?._PublicoAlvo &&
                                        <>
                                            <label id="product-description-title">Público-alvo</label>
                                            <label>{productInfo?._Etiquetas[0]?._PublicoAlvo?.Descricao}</label>
                                        </>
                                    }

                                    {productInfo?._Etiquetas[0]?._Peca.Nome &&
                                        <>
                                            <label id="product-description-title">Tipo de vestuário</label>
                                            <label>{productInfo._Etiquetas[0]._Peca.Nome}</label>
                                        </>
                                    }

                                    {productInfo?._Etiquetas[0]?._Peca._Material &&
                                        <>
                                            <label id="product-description-title">Material</label>
                                            <label>{productInfo._Etiquetas[0]._Peca._Material}</label>
                                        </>
                                    }

                                    {productInfo?._Etiquetas[0]?._Marca.Nome &&
                                        <>
                                            <label id="product-description-title">Marca</label>
                                            <label>{productInfo._Etiquetas[0]._Marca.Nome}</label>
                                        </>
                                    }

                                    <label id="product-description-title">Peça nova / semi-nova</label>
                                    <label>{productInfo?._Etiquetas[0]?._Marca?.FlagPecaNova ? 'Nova' : 'Semi-nova'}</label>

                                    {productInfo?._Usuario?._Endereco[0]?.END_Cidade &&
                                        <>
                                            <label id="product-description-title">Cidade de origem envio</label>
                                            <label>{productInfo._Usuario._Endereco[0].END_Cidade}/{productInfo._Usuario._Endereco[0].END_Estado}</label>
                                        </>
                                    }
                                </div>
                            }

                            {descriptionSelect === 'Vendedor' &&
                                <div className="product-description-vendedor">
                                    <img
                                        src={productInfo._Usuario.FotoProfile}
                                        style={{ width: 70, height: 70, borderRadius: '100%' }}
                                        alt=""
                                    />
                                    <div>
                                        <label style={{ fontStyle: 'italic', fontSize: 16 }}>{productInfo._Usuario.Nome}</label>
                                        <br />
                                        <label><span>{productInfo._Usuario.TotalSeguidores}</span> Seguidores</label>
                                        <br />
                                        <label><span>{productInfo._Usuario.TotalLooks}</span> Looks</label>
                                    </div>
                                    <div style={{ marginLeft: 'auto', textAlign: 'center' }}>
                                        <label><span>{productInfo._Usuario.TotalVendas}</span><br />Vendas</label>
                                        <br />
                                        <label><span>{productInfo._Usuario.MediaAvaliacaVendas}</span><br />Vendas</label>
                                    </div>
                                </div>

                            }
                        </div>
                    </div>
                </>
            }
        </div>
    )
}

export default StoreComp