// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.feed-content {
    padding: 15px 10px 80px 10px;
    -webkit-user-select: none;
            user-select: none;
}

.feed-content section {
    transition: all 0.5s;
}

.feed-content section:hover {
    transform: scale(1.01);
    /* box-shadow: 0px 10px 30px 13px rgba(0,0,0,0.1); */
}

.feed-content #feed-image-prod {
    transition: all 0.5s;
    height: 350px;
    width: auto;
    object-fit: cover;
    cursor: pointer;
}

.feed-content #feed-image-name {
    color: #fff;
    background-color: #000000bf;
    margin: -45px auto auto 5px;
    padding: 6px 10px 6px 10px;
    border-radius: 10px;
    font-size: 12px;
}

.feed-content #feed-image-store {
    background-color: #000;
    font-size: 13px;
    margin-top: -10px;
    padding: 10px 10px;
    display: flex;
    gap: 5px;
    align-items: center;
    color: #fff;
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
}


label, p {
    font-family: 'Archivo', sans-serif;
    font-family: 'Inter', sans-serif;
    font-family: 'Roboto', sans-serif;
}`, "",{"version":3,"sources":["webpack://./src/screens/feed/styles.css"],"names":[],"mappings":"AAAA;IACI,4BAA4B;IAC5B,yBAAiB;YAAjB,iBAAiB;AACrB;;AAEA;IACI,oBAAoB;AACxB;;AAEA;IACI,sBAAsB;IACtB,oDAAoD;AACxD;;AAEA;IACI,oBAAoB;IACpB,aAAa;IACb,WAAW;IACX,iBAAiB;IACjB,eAAe;AACnB;;AAEA;IACI,WAAW;IACX,2BAA2B;IAC3B,2BAA2B;IAC3B,0BAA0B;IAC1B,mBAAmB;IACnB,eAAe;AACnB;;AAEA;IACI,sBAAsB;IACtB,eAAe;IACf,iBAAiB;IACjB,kBAAkB;IAClB,aAAa;IACb,QAAQ;IACR,mBAAmB;IACnB,WAAW;IACX,+BAA+B;IAC/B,gCAAgC;AACpC;;;AAGA;IACI,kCAAkC;IAClC,gCAAgC;IAChC,iCAAiC;AACrC","sourcesContent":[".feed-content {\r\n    padding: 15px 10px 80px 10px;\r\n    user-select: none;\r\n}\r\n\r\n.feed-content section {\r\n    transition: all 0.5s;\r\n}\r\n\r\n.feed-content section:hover {\r\n    transform: scale(1.01);\r\n    /* box-shadow: 0px 10px 30px 13px rgba(0,0,0,0.1); */\r\n}\r\n\r\n.feed-content #feed-image-prod {\r\n    transition: all 0.5s;\r\n    height: 350px;\r\n    width: auto;\r\n    object-fit: cover;\r\n    cursor: pointer;\r\n}\r\n\r\n.feed-content #feed-image-name {\r\n    color: #fff;\r\n    background-color: #000000bf;\r\n    margin: -45px auto auto 5px;\r\n    padding: 6px 10px 6px 10px;\r\n    border-radius: 10px;\r\n    font-size: 12px;\r\n}\r\n\r\n.feed-content #feed-image-store {\r\n    background-color: #000;\r\n    font-size: 13px;\r\n    margin-top: -10px;\r\n    padding: 10px 10px;\r\n    display: flex;\r\n    gap: 5px;\r\n    align-items: center;\r\n    color: #fff;\r\n    border-bottom-left-radius: 10px;\r\n    border-bottom-right-radius: 10px;\r\n}\r\n\r\n\r\nlabel, p {\r\n    font-family: 'Archivo', sans-serif;\r\n    font-family: 'Inter', sans-serif;\r\n    font-family: 'Roboto', sans-serif;\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
