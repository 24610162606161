
import { Router } from "./screens/Routes";

function App() {
  return (
    <Router />
  );
}

export default App;
