import React, { useState, useEffect } from 'react';
// import { useNavigate } from 'react-router-dom';
import callApi from '../../services/api'
import Slider from '@mui/material/Slider';

import logo from '../../assets/logo-white.png'

import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import Menu from '@mui/material/Menu';
import Container from '@mui/material/Container';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import Tooltip from '@mui/material/Tooltip';
import MenuItem from '@mui/material/MenuItem';
import MenuOutlinedIcon from '@mui/icons-material/MenuOutlined';
import ShoppingCartOutlinedIcon from '@mui/icons-material/ShoppingCartOutlined';
import TuneOutlinedIcon from '@mui/icons-material/TuneOutlined';
import FavoriteOutlinedIcon from '@mui/icons-material/FavoriteOutlined';
import MenuIcon from '@mui/icons-material/Menu';





import SwipeableDrawer from '@mui/material/SwipeableDrawer';
import HighlightOffOutlinedIcon from '@mui/icons-material/HighlightOffOutlined';
import { Checkbox } from '@mui/material';
import ArrowForwardOutlinedIcon from '@mui/icons-material/ArrowForwardOutlined';
import List from '@mui/material/List';


import './styles.css'

function Header(props) {
    const [user, setUser] = useState({ nome: 'Gustavo' })
    // const navigate = useNavigate()

    const [filterOption, setFilterOption] = useState(false)
    const [menuSelected, setMenuSelected] = useState('Lojas')

    const [category, setCategory] = useState([])
    const [tipoPeca, setTipoPeca] = useState([])
    const [tamanho, setTamanho] = useState([])
    const [marcas, setMarcas] = useState([])
    const [tags, setTags] = useState([])
    const submenu = ['Lojas', 'Desapegos', 'Brechós', 'Avaliar Looks', 'Trocas', 'Todos Looks']

    const [selectedItems, setSelectedItems] = useState({
        tipoLook: [],
        categoria: null,
        tipoPeca: [],
        tamanho: [],
        marcas: [],
        tags: [],
        valorPeca: [0, 1000],
        leaper: null,
        favLook: false,
        tipoLookCheck: false,
        tipoPecasCheck: false,
        tipoTamanhoCheck: false,
        tipoMarcasCheck: false,
        tipoTagsCheck: false,
    })

    const [state, setState] = useState({ left: false });
    const toggleDrawer = (anchor, open) => () => {
        setState({ ...state, [anchor]: open });

        console.log(anchor, open)
    };

    const selectAllButton = (item) => {
        if (item === 'Tipos de Look') {
            setSelectedItems(prevState => ({
                ...prevState,
                tipoLookCheck: !prevState.tipoLookCheck,
                tipoLook: !prevState.tipoLookCheck
                    ? [{ id: 1, name: 'Masculino' }, { id: 2, name: 'Feminino' }, { id: 3, name: 'Unissex' }, { id: 4, name: 'Infantil' }]
                    : []
            }));
        }

        if (item === 'Tipos de peça') {
            setSelectedItems(prevState => ({
                ...prevState,
                tipoPecasCheck: !prevState.tipoPecasCheck,
                tipoPeca: !prevState.tipoPecasCheck
                    ? tipoPeca
                    : []
            }));
        }

        if (item === 'Tamanho') {
            setSelectedItems(prevState => ({
                ...prevState,
                tipoTamanhoCheck: !prevState.tipoTamanhoCheck,
                tamanho: !prevState.tipoTamanhoCheck
                    ? tamanho
                    : []
            }));
        }

        if (item === 'Marcas') {
            setSelectedItems(prevState => ({
                ...prevState,
                tipoMarcasCheck: !prevState.tipoMarcasCheck,
                marcas: !prevState.tipoMarcasCheck
                    ? marcas
                    : []
            }));
        }

        if (item === 'Tags') {
            setSelectedItems(prevState => ({
                ...prevState,
                tipoTagsCheck: !prevState.tipoTagsCheck,
                tags: !prevState.tipoTagsCheck
                    ? tags
                    : []
            }));
        }
    }

    const handleTipoLookClick = (item) => {
        const itemIndex = selectedItems.tipoLook.findIndex((el) => el.IdPeca === item.IdPeca);

        if (itemIndex !== -1) {
            const updatedTipoLook = [...selectedItems.tipoLook];
            updatedTipoLook.splice(itemIndex, 1);
            setSelectedItems({ ...selectedItems, tipoLook: updatedTipoLook });
        } else {
            setSelectedItems({ ...selectedItems, tipoLook: [...selectedItems.tipoLook, item] });
        }
    };

    const handleTipoPecasClick = (item) => {
        selectedItems.tipoPeca.includes(item)
            ? setSelectedItems({ ...selectedItems, tipoPeca: [...selectedItems.tipoPeca.filter((id) => id !== item)] })
            : setSelectedItems({ ...selectedItems, tipoPeca: [...selectedItems.tipoPeca, item] })
    };

    const handleTamanhoClick = (item) => {
        selectedItems.tamanho.includes(item)
            ? setSelectedItems({ ...selectedItems, tamanho: [...selectedItems.tamanho.filter((id) => id !== item)] })
            : setSelectedItems({ ...selectedItems, tamanho: [...selectedItems.tamanho, item] })
    };

    const handleMarcasClick = (item) => {
        selectedItems.marcas.includes(item)
            ? setSelectedItems({ ...selectedItems, marcas: [...selectedItems.marcas.filter((id) => id.IdMarca !== item.IdMarca)] })
            : setSelectedItems({ ...selectedItems, marcas: [...selectedItems.marcas, item] })
    };

    const handleTagsClick = (item) => {
        selectedItems.tags.includes(item)
            ? setSelectedItems({ ...selectedItems, tags: [...selectedItems.tags.filter((id) => id.IdHashTag !== item.IdHashTag)] })
            : setSelectedItems({ ...selectedItems, tags: [...selectedItems.tags, item] })
    };

    const getAllPecasByValorExato = () => {
        try {
            let config = {
                method: 'post',
                url: `/Peca/GetAllPecaByValorExato?strValorExato=${selectedItems.categoria?.IdCategoria}&ColunaParaValorExato=IdCategoria&fSomenteAtivos=true&join=true&maxInstances=0&order_by=Nome`,
            };

            callApi(config)
                .then((response) => {
                    setTipoPeca(response.data);
                })
                .catch((error) => {
                    console.log(error);
                });
        } catch (err) {
            throw new Error(err);
        }
    }

    const getCategoriesList = () => {
        try {
            let config = {
                method: 'post',
                url: '/Categoria/GetAllCategoria?fVerTodos=true&fSomenteAtivos=true&join=true&maxInstances=0&order_by=idCategoria',
            };

            callApi(config)
                .then((response) => {
                    setCategory(response.data)
                })
                .catch((error) => {
                    console.log(error);
                });
        } catch (err) {
            console.log('CATEGORIES ERROR >>>>>', err);
            throw new Error(err);
        }
    }

    const GetAllTamanhoByValorExato = () => {
        try {
            let config = {
                method: 'post',
                url: `/Tamanho/GetAllTamanhoByValorExato?strValorExato=${selectedItems.categoria?.IdCategoria}&ColunaParaValorExato=IdCategoria&fSomenteAtivos=true&join=true&flagKids=false&maxInstances=0&order_by=IdTamanho`,
            };

            callApi(config)
                .then((response) => {
                    setTamanho(response.data);
                })
                .catch((error) => {
                    console.log(error);
                });
        } catch (err) {
            throw new Error(err);
        }
    }

    const GetAllMarcas = () => {
        try {
            let config = {
                method: 'post',
                url: '/Marca/GetAllMarca?fSomenteAtivos=true&join=true&maxInstances=0&fVerTodos=true&order_by=Nome',
            };

            callApi(config)
                .then((response) => {
                    setMarcas(response.data);
                })
                .catch((error) => {
                    console.log(error);
                });
        } catch (err) {
            throw new Error(err);
        }
    }

    const getHastags = () => {
        try {
            let config = {
                method: 'post',
                url: '/Usuario/GetListHashtags',
            };

            callApi(config)
                .then((response) => {
                    setTags(response.data);
                })
                .catch((error) => {
                    console.log(error);
                });
        } catch (err) {
            throw new Error(err);
        }
    }

    const itemPress = (item) => {
        setMenuSelected(item)

        props.selectedPage(item)
    }

    const sliderPressed = (event, newValue) => {
        setSelectedItems({ ...selectedItems, valorPeca: newValue })
    };

    const list = (anchor) => {
        return (
            <Box
                sx={{ width: anchor === 'top' || anchor === 'bottom' ? 'auto' : '100vw' }}
                role="presentation"
                // onClick={toggleDrawer(anchor, false)}
                onKeyDown={toggleDrawer(anchor, false)}
            >
                <List>
                    {!filterOption &&
                        <>
                            <div className='feed-filter-menu'>
                                <div style={{ display: 'flex', alignItems: 'center' }}>
                                    <TuneOutlinedIcon htmlColor='#ff8e00' fontSize='medium' />
                                    <label style={{ color: '#ff8e00', fontWeight: 'bold' }}>Filtros</label>
                                </div>
                                <div className='feed-filter-button'>
                                    <div
                                        style={{ display: 'flex', alignItems: 'center' }}
                                        onClick={() => {
                                            setSelectedItems({
                                                tipoLook: [],
                                                categoria: null,
                                                tipoPeca: [],
                                                tamanho: [],
                                                marcas: [],
                                                tags: null,
                                                valorPeca: [0, 1000],
                                                leaper: null,
                                                favLook: false
                                            })
                                            setState({ left: false })
                                        }}
                                    >
                                        <HighlightOffOutlinedIcon htmlColor='#fff' fontSize='small' />
                                        <label>Limpar</label>
                                    </div>
                                    <div onClick={() => {
                                        setState({ left: false })
                                        props.selectedFilter(selectedItems)
                                    }}>
                                        <label>Aplicar</label>
                                    </div>
                                </div>
                            </div>
                            <div>
                                <Checkbox checked={selectedItems.favLook} size='small' onClick={() => setSelectedItems({ ...selectedItems, favLook: !selectedItems.favLook })} />
                                <label style={{ fontWeight: 'bold' }}>Looks Favoritos</label>
                            </div>
                            {['Tipos de Look', 'Categoria', 'Tipos de peça', 'Tamanho', 'Marcas', 'Tags', 'Valor de Peça', 'Leapers'].map((text, index) => (
                                <div key={index} onClick={() => setFilterOption(text)} className='feed-option-filtros'>
                                    <div>
                                        <label id='feed-option-filtros-title'>{text}</label>

                                        {text === 'Tipos de Look' && <label id='feed-option-filtros-dec'>{selectedItems?.tipoLook?.length > 0 ? selectedItems.tipoLook.map((item) => `${item.name} `) : 'Selecione'}</label>}
                                        {text === 'Categoria' && <label id='feed-option-filtros-dec'>{selectedItems.categoria ? selectedItems.categoria.Descricao : 'Selecione'}</label>}
                                        {text === 'Tipos de peça' && <label id='feed-option-filtros-dec'>{selectedItems?.tipoPeca?.length > 0 ? selectedItems.tipoPeca.map((item) => `${item.Nome} `) : 'Selecione'}</label>}
                                        {text === 'Tamanho' && <label id='feed-option-filtros-dec'>{selectedItems?.tamanho?.length > 0 ? selectedItems.tamanho.map((item) => `${item.Descricao} `) : 'Selecione'}</label>}
                                        {text === 'Marcas' && <label id='feed-option-filtros-dec'>{selectedItems?.marcas?.length > 0 ? selectedItems.marcas.map((item) => `${item.Nome} `) : 'Selecione'}</label>}
                                        {text === 'Tags' && <label id='feed-option-filtros-dec'>{selectedItems?.tags?.length > 0 ? selectedItems.tags.map((item) => `#${item.Descricao} `) : 'Selecione'}</label>}
                                        {text === 'Valor de Peça' && <label id='feed-option-filtros-dec'>{selectedItems.valorPeca ? `R$ ${selectedItems.valorPeca[0]} - R$ ${selectedItems.valorPeca[1]}` : 'Selecione'}</label>}
                                        {text === 'Leapers' && <label id='feed-option-filtros-dec'>{selectedItems.leaper ? 'Leaper' : 'Selecione'}</label>}
                                    </div>
                                    <ArrowForwardOutlinedIcon htmlColor='#b0b0b0' fontSize='large' />
                                </div>
                            ))}
                        </>
                    }

                    {filterOption === 'Tipos de Look' &&
                        <>
                            <div className='feed-title-filter'>
                                <label onClick={() => setFilterOption(false)} id='feed-button'>Ok</label>
                                <label>Tipos de Look</label>
                            </div>
                            <div className='feed-checkbox-filter'>
                                <Checkbox checked={selectedItems.tipoLookCheck} size='small' onClick={() => selectAllButton('Tipos de Look')} />
                                <label>Selecionar Todas</label>
                            </div>
                            <div className='feed-item-option'>
                                {[{ id: 1, name: 'Masculino' }, { id: 2, name: 'Feminino' }, { id: 3, name: 'Unissex' }, { id: 4, name: 'Infantil' }].map((item, index) => (
                                    <label
                                        onClick={() => handleTipoLookClick(item)}
                                        id={selectedItems.tipoLook.some((el) => el.id === item.id) ? 'feed-item-option-active' : ''}
                                        key={index}
                                    >
                                        {item.name}
                                    </label>
                                ))}
                            </div>
                        </>
                    }

                    {filterOption === 'Categoria' &&
                        <>
                            <div className='feed-title-filter'>
                                <label onClick={() => setFilterOption(false)} id='feed-button'>Ok</label>
                                <label>Categorias</label>
                            </div>
                            <div className='feed-item-option'>
                                {category?.map((result, index) => (
                                    <label
                                        id={selectedItems?.categoria?.IdCategoria === result?.IdCategoria ? 'feed-item-option-active' : ''}
                                        onClick={() => setSelectedItems({ ...selectedItems, categoria: result })}
                                        key={index}
                                    >
                                        {result.Descricao}
                                    </label>
                                ))}
                            </div>
                        </>
                    }

                    {filterOption === 'Tipos de peça' &&
                        <>
                            <div className='feed-title-filter'>
                                <label onClick={() => setFilterOption(false)} id='feed-button'>Ok</label>
                                <label>Tipos de peça</label>
                            </div>
                            <div className='feed-checkbox-filter'>
                                <Checkbox checked={selectedItems.tipoPecasCheck} size='small' onClick={() => selectAllButton('Tipos de peça')} />
                                <label>Selecionar Todas</label>
                            </div>

                            <div className='feed-item-option'>
                                {tipoPeca?.map((item, index) => (
                                    <label
                                        onClick={() => handleTipoPecasClick(item)}
                                        id={selectedItems.tipoPeca.some((el) => el.IdPeca === item.IdPeca) ? 'feed-item-option-active' : ''}
                                        key={index}
                                    >
                                        {item.Nome}
                                    </label>
                                ))}
                            </div>
                        </>
                    }

                    {filterOption === 'Tamanho' &&
                        <>
                            <div className='feed-title-filter'>
                                <label onClick={() => setFilterOption(false)} id='feed-button'>Ok</label>
                                <label>Tamanho</label>
                            </div>
                            <div className='feed-checkbox-filter'>
                                <Checkbox checked={selectedItems.tipoTamanhoCheck} size='small' onClick={() => selectAllButton('Tamanho')} />
                                <label>Selecionar Todas</label>
                            </div>
                            <div className='feed-item-option'>
                                {tamanho?.map((item, index) => (
                                    <label
                                        id={selectedItems.tamanho.some((el) => el.IdTamanho === item.IdTamanho) ? 'feed-item-option-active' : ''}
                                        onClick={() => handleTamanhoClick(item)}
                                    >
                                        {item.Descricao}
                                    </label>
                                ))}
                            </div>
                        </>
                    }

                    {filterOption === 'Marcas' &&
                        <>
                            <div className='feed-title-filter'>
                                <label onClick={() => setFilterOption(false)} id='feed-button'>Ok</label>
                                <label>Marcas</label>
                            </div>
                            <div className='feed-checkbox-filter'>
                                <Checkbox checked={selectedItems.tipoMarcasCheck} size='small' onClick={() => selectAllButton('Marcas')} />
                                <label>Selecionar Todas</label>
                            </div>
                            <label>PESQUISA</label>
                            <div className='feed-item-option'>
                                {marcas?.map((item, index) => (
                                    <label
                                        id={selectedItems.marcas.some((el) => el.IdMarca === item.IdMarca) ? 'feed-item-option-active' : ''}
                                        onClick={() => handleMarcasClick(item)}
                                        key={index}
                                    >
                                        {item.Nome}
                                    </label>
                                ))}
                            </div>
                        </>
                    }

                    {filterOption === 'Tags' &&
                        <>
                            <div className='feed-title-filter'>
                                <label onClick={() => setFilterOption(false)} id='feed-button'>Ok</label>
                                <label>Tags</label>
                            </div>
                            <div className='feed-checkbox-filter'>
                                <Checkbox checked={selectedItems.tipoTagsCheck} size='small' onClick={() => selectAllButton('Tags')} />
                                <label>Selecionar Todas</label>
                            </div>

                            <div className='feed-item-option'>
                                {tags?.map((item, index) => (
                                    <label
                                        // id={selectedItems.tags === result.IdHashTag ? 'feed-item-option-active' : ''}
                                        // onClick={() => setSelectedItems({ ...selectedItems, tags: result.IdHashTag })}
                                        id={selectedItems.tags.some((el) => el.IdHashTag === item.IdHashTag) ? 'feed-item-option-active' : ''}
                                        // id={selectedItems.tags.includes(`#${item.Descricao}`) ? 'feed-item-option-active' : ''}
                                        onClick={() => handleTagsClick(item)}
                                        // onClick={() =>
                                        //     selectedItems.tags.includes(`#${item.Descricao}`)
                                        //         ? setSelectedItems({ ...selectedItems, tags: [...selectedItems.tags.filter((id) => id !== `#${item.Descricao}`)] })
                                        //         : setSelectedItems({ ...selectedItems, tags: [...selectedItems.tags, `#${item.Descricao}`] })
                                        // }
                                        key={index}
                                    >
                                        #{item.Descricao}
                                    </label>
                                ))}
                            </div>
                        </>
                    }

                    {filterOption === 'Valor de Peça' &&
                        <>
                            <div className='feed-title-filter'>
                                <label onClick={() => setFilterOption(false)} id='feed-button'>Ok</label>
                                <label>Valor de Peça</label>
                            </div>

                            <div style={{ marginLeft: 20, marginRight: 20, marginTop: 100, userSelect: 'none' }}>
                                <div style={{ display: 'flex', justifyContent: 'space-evenly', marginBottom: 50 }}>
                                    <label>{selectedItems.valorPeca[0].toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}</label>
                                    -
                                    <label>{selectedItems.valorPeca[1].toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}</label>
                                </div>
                                <Slider
                                    value={selectedItems.valorPeca}
                                    onChange={sliderPressed}
                                    valueLabelDisplay="auto"
                                    max={1000}
                                    style={{ color: '#da027c' }}
                                />
                            </div>
                        </>
                    }

                    {filterOption === 'Leapers' &&
                        <>
                            <div className='feed-title-filter'>
                                <label onClick={() => setFilterOption(false)} id='feed-button'>Ok</label>
                                <label>Tags</label>
                            </div>

                            <div className='feed-item-option'>
                                <label
                                    id={selectedItems.leaper ? 'feed-item-option-active' : ''}
                                    onClick={() => setSelectedItems({ ...selectedItems, leaper: !selectedItems.leaper })}
                                >
                                    Leapers
                                </label>
                            </div>
                        </>
                    }
                </List>
            </Box>

        )
    };

    useEffect(() => {
        if (filterOption === 'Categoria') {
            getCategoriesList()
        }

        if (filterOption === 'Tipos de peça') {
            getAllPecasByValorExato()
        }

        if (filterOption === 'Tamanho') {
            GetAllTamanhoByValorExato()
        }

        if (filterOption === 'Marcas') {
            GetAllMarcas()
        }

        if (filterOption === 'Tags') {
            getHastags()
        }
    }, [filterOption])

    return (
        <AppBar position="sticky" style={{ backgroundColor: '#000', userSelect: 'none' }}>
            <Container maxWidth="xl">
                <Toolbar disableGutters>

                    <div style={{ marginRight: 25, cursor: 'pointer', marginTop: 5 }}>
                        <React.Fragment>
                            <TuneOutlinedIcon onClick={toggleDrawer('left', true)} className='feed-menu-icon' htmlColor='#fff' fontSize='medium'></TuneOutlinedIcon>
                            <SwipeableDrawer
                                anchor={'left'}
                                open={state['left']}
                                onClose={toggleDrawer('left', false)}
                                onOpen={toggleDrawer('left', true)}
                            >
                                {list('left')}
                            </SwipeableDrawer>
                        </React.Fragment>
                    </div>

                    <Typography
                        variant="h5"
                        noWrap
                        component="a"
                        href=""
                        sx={{
                            display: { xs: 'flex', md: 'none' },
                            flexGrow: 1,
                            color: 'inherit',
                            textDecoration: 'none',
                            justifyContent: 'center',
                        }}
                    >
                        <img src={logo} style={{ width: 80, height: '100%' }} alt='' />
                    </Typography>

                    <div style={{ marginLeft: 25, cursor: 'pointer', marginTop: 5 }}>
                        <ShoppingCartOutlinedIcon htmlColor='#fff' fontSize='medium' />
                    </div>
                </Toolbar>
            </Container>
            <Container maxWidth="xl" className='menu-slider-container'>
                <Toolbar disableGutters style={{ minHeight: 40, paddingBottom: 5 }}>
                    <div className='menu-slider-content'>
                        <FavoriteOutlinedIcon onClick={() => itemPress('Favorito')} htmlColor={menuSelected === 'Favorito' ? '#ff8700' : '#fff'} fontSize='small' />
                        {submenu.map((res, index) => (
                            <label
                                key={index}
                                onClick={() => itemPress(res)}
                                style={{
                                    color: res === menuSelected && '#ff8700',
                                    borderBottomStyle: res === menuSelected && 'solid',
                                    paddingBottom: res === menuSelected && 3,
                                    borderWidth: 3,
                                    borderRadius: 1,
                                }}
                            >
                                {res}
                            </label>
                        ))}
                    </div>
                </Toolbar>
            </Container>
        </AppBar>
    );
}

export default Header;