// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.bottom-header-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 0.6rem;
    padding: 0.625rem 2rem;
    width: 100%;
}

.bottom-header-container section {
    display: grid;
    text-align: center;
    justify-items: center;
}
`, "",{"version":3,"sources":["webpack://./src/components/BottomMenu/styles.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,8BAA8B;IAC9B,mBAAmB;IACnB,iBAAiB;IACjB,sBAAsB;IACtB,WAAW;AACf;;AAEA;IACI,aAAa;IACb,kBAAkB;IAClB,qBAAqB;AACzB","sourcesContent":[".bottom-header-container {\r\n    display: flex;\r\n    justify-content: space-between;\r\n    align-items: center;\r\n    font-size: 0.6rem;\r\n    padding: 0.625rem 2rem;\r\n    width: 100%;\r\n}\r\n\r\n.bottom-header-container section {\r\n    display: grid;\r\n    text-align: center;\r\n    justify-items: center;\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
