import React, { useEffect, useState } from "react";
import { Carousel } from 'react-responsive-carousel';
import { useNavigate } from "react-router-dom";

import './styles.css'

import ArrowBackOutlinedIcon from '@mui/icons-material/ArrowBackOutlined';
import MoreHorizOutlinedIcon from '@mui/icons-material/MoreHorizOutlined';
import ArrowForwardOutlinedIcon from '@mui/icons-material/ArrowForwardOutlined';
// import ChevronRightOutlinedIcon from '@mui/icons-material/ChevronRightOutlined';
import FavoriteBorderOutlinedIcon from '@mui/icons-material/FavoriteBorderOutlined';
// import FavoriteOutlinedIcon from '@mui/icons-material/FavoriteOutlined';

function ImageScreen(props) {
    const navigate = useNavigate()
    const [productInfo, setProductInfo] = useState(null)
    const menuItem = ['Peças', 'Opinar', 'Perfil']
    const [menuItemPress, setMenuItemPress] = useState('Peças')
    const [hashtags, setHashtags] = useState([])

    useEffect(() => {
        setProductInfo(props.productInfo)
    }, [props])

    useEffect(() => {
        if (props.productInfo.Tag) {
            const apiItem = props.productInfo.Tag
            const arrayFromApiData = apiItem.split(';').map(item => item.trim());
            setHashtags(arrayFromApiData)
        }
    }, [props.productInfo])

    return (
        <div>
            {productInfo &&
                <>
                    <div className="image-screen-header">

                        <div onClick={() => navigate('/feed')}>
                            <ArrowBackOutlinedIcon
                                htmlColor="#fff"
                                fontSize="medium"
                                style={{
                                    backgroundColor: '#2c2c2c',
                                    borderRadius: '100%',
                                    padding: 5,
                                    marginLeft: 20
                                }}
                            />
                        </div>
                        <FavoriteBorderOutlinedIcon
                            htmlColor="#fff"
                            fontSize="medium"
                            style={{
                                backgroundColor: '#2c2c2c9f',
                                borderRadius: 5,
                                padding: 5,
                                position: 'absolute',
                                left: 20,
                                marginTop: 50
                            }}
                        />

                        {productInfo?._ImagensComplementares &&
                            <div id='image-screen-header-user'>
                                <img
                                    src={productInfo?._ImagensComplementares[0]?._Usuario.FotoProfile}
                                    style={{ width: 45, height: 45, borderRadius: '100%', marginLeft: 100, marginRight: 100, marginBottom: 3 }}
                                    alt=""
                                />
                                <label>{productInfo?._ImagensComplementares[0]?._Usuario.Nome}</label>
                            </div>
                        }

                        <div>
                            <MoreHorizOutlinedIcon htmlColor="#fff" fontSize="medium" style={{ backgroundColor: '#2c2c2c', borderRadius: '100%', marginRight: 20, padding: 5 }} />
                            <div style={{ display: 'grid', position: 'absolute', right: 0, marginRight: 10, marginTop: 20 }}>
                                {hashtags && hashtags.map((res, index) => (
                                    <label
                                        key={index}
                                        style={{
                                            backgroundColor: '#0000003f',
                                            borderRadius: 20,
                                            padding: '3px 20px',
                                            marginBottom: 3,
                                            color: '#fff',
                                            fontSize: 14
                                        }}
                                    >
                                        {res}
                                    </label>
                                ))}
                            </div>
                        </div>
                    </div>

                    <div className="image-screen-menu">
                        {menuItem.map((res, index) => (
                            <label
                                key={index}
                                style={{ backgroundColor: menuItemPress === res ? '#000' : '#0000004f' }}
                                onClick={() => setMenuItemPress(res)}
                            >
                                {res}
                            </label>
                        ))}
                    </div>

                    {menuItemPress === 'Peças' &&
                        <div onClick={() => props.selectScreenPress()} className="image-screen-pecas-container">
                            <div className="image-screen-info">
                                {productInfo._Etiquetas &&
                                    <div style={{
                                        width: '15px',
                                        height: '15px',
                                        borderRadius: '100%',
                                        backgroundColor: productInfo?._Etiquetas[0]?.Cor
                                    }} />
                                }
                                {productInfo?._Etiquetas &&
                                    <div style={{ display: 'grid', marginLeft: 10, gap: 3 }}>
                                        <label>{productInfo?._Etiquetas[0]?._Peca.Nome} - {productInfo?._Etiquetas[0]?.sTamanho}</label>
                                        <label style={{ fontWeight: 'bold' }}>{productInfo?._Etiquetas[0]?._Marca.Nome}</label>
                                        <label>{productInfo?._Etiquetas[0]?.FlagPecaNova ? 'Peça nova' : 'Peça seminova'}</label>
                                        <label style={{ fontStyle: 'normal', fontWeight: 'bold', fontSize: 18 }}>{productInfo?._Etiquetas[0]?.mValor.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}</label>
                                    </div>
                                }
                            </div>
                        </div>
                    }

                    {menuItemPress === 'Opinar' &&
                        <div className="image-screen-opinar-container">
                            <div className="image-screen-opinar">
                                <div id="image-screen-opinar-card" style={{ display: 'grid', gap: 3 }}>
                                    <label>Curtiu esse look?</label>
                                    <div>
                                        <label style={{ color: '#77ca20' }}>SIM</label>
                                        <label>|</label>
                                        <label style={{ color: '#ee3242' }}>NÃO</label>
                                    </div>
                                </div>
                            </div>
                        </div>
                    }

                    {menuItemPress === 'Perfil' &&
                        <div className="image-screen-perfil-container">
                            <div className="image-screen-perfil">
                                <div id="image-screen-perfil-card">
                                    {productInfo?._Etiquetas &&
                                        <img alt="" src={productInfo?._ImagensComplementares[0]?._Usuario.FotoProfile} />
                                    }

                                    <div>
                                        <label>{productInfo?._Usuario.Nome}</label>
                                        <label style={{ color: "#c5ad6c", marginBottom: 10 }}>{productInfo?._Usuario.Nome}</label>
                                        <label><span style={{ fontStyle: 'normal', fontWeight: 'bold', fontSize: 16 }}>{productInfo?._Usuario.TotalSeguidores}</span> seguidores</label>
                                        <label><span style={{ fontStyle: 'normal', fontWeight: 'bold', fontSize: 16 }}>{productInfo?._Usuario.TotalLooks}</span> looks</label>
                                    </div>

                                    <div style={{ marginLeft: 100 }}>
                                        <ArrowForwardOutlinedIcon htmlColor="#fff" fontSize="medium" style={{ backgroundColor: '#2c2c2c', borderRadius: '100%', padding: 5, marginLeft: 20 }} />
                                    </div>
                                </div>
                            </div>
                        </div>
                    }

                    <Carousel
                        infiniteLoop={true}
                        showThumbs={false}
                        showArrows={true}
                        autoPlay={false}
                        showStatus={false}
                    >
                        <div style={{ height: '100vh' }}>
                            {productInfo._Etiquetas &&
                                <div style={{
                                    position: 'absolute',
                                    top: productInfo?._Etiquetas[0]?.Y, // Y coordinate
                                    left: productInfo?._Etiquetas[0]?.X, // X coordinate
                                    width: '15px',
                                    height: '15px',
                                    borderRadius: '100%',
                                    backgroundColor: productInfo?._Etiquetas[0]?.Cor
                                }} />
                            }
                            <img alt="" src={productInfo.LocalIIS} style={{ width: '100vw', height: '100vh', objectFit: 'cover' }} />
                        </div>
                        {productInfo._ImagensComplementares &&
                            productInfo._ImagensComplementares.map((res, index) => (
                                <div style={{ height: '100vh' }} key={index}>
                                    <img alt="" src={res.LocalIIS} style={{ width: '100vw', height: '100vh', objectFit: 'cover' }} />
                                </div>
                            ))
                        }
                    </Carousel>
                </>
            }
        </div>
    )
}

export default ImageScreen