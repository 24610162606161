import React, { useState, useEffect } from 'react'
import { useNavigate } from 'react-router-dom';
import Masonry from "react-responsive-masonry"
import callApi from '../../services/api'

import StoreRoundedIcon from '@mui/icons-material/StoreRounded';

import Header from '../../components/Menu';
import BottomHeader from '../../components/BottomMenu';
import './styles.css'

function Feed() {
    const navigate = useNavigate()
    const [allImages, setAllImages] = useState([]);
    const [loading, setLoading] = useState(true)
    const [qntPagina, setQntPagina] = useState(0)
    const [maxPagina, setMaxPagina] = useState(1)
    const [filterItems, setFilterItems] = useState({
        tipoLook: null
    })
    const [tabView, setTabView] = useState({
        fAvaliacao: false,
        fFavoritos: false,
        fLoja: true,
        fTrocas: false,
        fUsado: false,
        fVendas: false,
    })

    const getImage = (filter) => {
        console.log(filter)
        if (qntPagina !== 0 && qntPagina <= maxPagina) {
            try {
                let data = JSON.stringify({
                    "fAvaliacao": tabView.fAvaliacao,
                    "fFavoritos": tabView.fFavoritos,
                    "fLoja": tabView.fLoja,
                    "fTrocas": tabView.fTrocas,
                    "fUsado": tabView.fUsado,
                    "fVendas": tabView.fVendas,
                    "iPagina": qntPagina,
                    "idUsuario": 46,
                    "join": true,
                    "Sexo": filter?.tipoLook?.lenght && filter?.tipoLook !== 4 ? filter?.tipoLook.id.toString() : null,
                    "Categoria": filter?.categoria ?  filter?.categoria.IdCategoria : null,
                    "Peca": filter?.tipoPeca?.lenght ? filter?.tipoPeca.IdPeca.toString() : null,
                    "Tamanho": filter?.tamanho?.lenght ? filter?.tamanho.IdTamanho.toString() : null,
                    "Marca": filter?.marcas?.lenght ? filter?.marcas.IdMarca.toString() : null,
                    "ValorMinimo": filter?.valorPeca?.lenght ? filter?.valorPeca[0] : null,
                    "ValorMaximo": filter?.valorPeca?.lenght ? filter?.valorPeca[1] : null,
                    "HashTags": filter?.tags?.lenght ? filter?.tags.IdHashTag.toString() : null,
                    "FlgFavoritos": filter?.favLook ? filter.favLook : false,
                    "FlagKids": filter?.tipoLook === 4 ? true : false,
                    // "FlgSomenteSeguindo":false,
                })

                let config = {
                    method: 'put',
                    maxBodyLength: Infinity,
                    url: '/Imagem/GetListImagemFilterPagination',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    data: data
                };

                callApi(config)
                    .then((response) => {
                        if (response.status === 200) {
                            setMaxPagina(response.data.iTotalPagina)
                            const newImages = response.data._Imagens;
                            setAllImages((prevImages) => [...prevImages, ...newImages]);
                            setLoading(false)
                        }
                    })
                    .catch((error) => {
                        console.log(error);
                    });
            } catch (err) {
                console.log(err)
            }
        }
    }

    const selectPage = (item) => {
        // '', 'Desapegos', '', , '', 'Todos Looks'
        setAllImages([])
        setTabView({
            fAvaliacao: item === 'Avaliar Looks' ? true : false,
            fFavoritos: item == 'Favorito' ? true : false,
            fLoja: item === 'Lojas' ? true : false,
            fTrocas: item === 'Trocas' ? true : false,
            fUsado: item === 'Brechós' ? true : false,
            fVendas: item === 'Vendas' ? true : false,
            idUsuario: 46
        })
        getImage()
    }

    const selectedFilter = (item) => {
        setAllImages([])
        setFilterItems(item)
        getImage(item)
    }

    useEffect(() => {
        if (allImages && !loading) {
            function handleScroll() {
                if (window.innerHeight + document.documentElement.scrollTop === document.documentElement.offsetHeight) {
                    setQntPagina((prevQntPagina) => {
                        var teste = prevQntPagina + 1;
                        // getImage(true);
                        return teste;
                    });
                }
            }

            window.addEventListener('scroll', handleScroll);
            return () => {
                window.removeEventListener('scroll', handleScroll);
            };
        }
    }, [allImages, qntPagina]);

    useEffect(() => {
        getImage()
    }, [qntPagina, tabView])

    useEffect(() => {
        setQntPagina(1)
    }, [])

    return (
        <div>
            <Header selectedPage={selectPage} selectedFilter={selectedFilter} />
            <div className='feed-content'>
                {!loading &&
                    <>
                        <Masonry columnsCount={2} gutter="20px">
                            {allImages.map((image, i) => (
                                <section key={i} onClick={() => navigate(`/product/${image.idImagem}`)}>
                                    <img
                                        key={i}
                                        id="feed-image-prod"
                                        src={image.LocalIIS}
                                        style={{ width: "100%", display: "block", borderRadius: 10 }}
                                        alt=''
                                    />

                                    <div style={{ display: 'grid' }}>
                                        <label id='feed-image-name'>{image._Etiquetas[0]?._Peca.Nome} {image._Etiquetas[0]?.mValor.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}</label>
                                        <div id='feed-image-store'>
                                            <StoreRoundedIcon htmlColor='#fff' fontSize='small' />
                                            <label>{image._Usuario.Nome}</label>
                                        </div>
                                    </div>
                                </section>
                            ))}
                        </Masonry>
                    </>
                }
            </div>
            <BottomHeader />
        </div>
    )
}

export default Feed


//     if (response.status === 200 && response.data) {
//         const imagesResponse = response.data._Imagens
//             .map((item) => {
//                 let onSale = false;
//                 if (item._Etiquetas) {
//                     if (item._Etiquetas.length > 0) {
//                         onSale = item._Etiquetas.some(
//                             (onSaleItem) => onSaleItem.FlagDisponivelVenda === true,
//                         );
//                     }
//                 }
//                 return {
//                     id: item.idImagem,
//                     userId: item._Usuario?.IdUsuario,
//                     hashtags: item.Tag?.split(';'),
//                     percent: item.LikesMedia,
//                     source: { uri: item.LocalIIS },
//                     dimensions: { width: 1000, height: 1000 },
//                     etiquetas: item._Etiquetas,
//                     onSale: onSale,
//                     onWardrobe: item.FlgGuardaRoupa,
//                     storeName: item._Usuario?.Nome,
//                     FlgLoja: item.FlgLoja,
//                 };
//             })
//             .reduce((carry, item) => {
//                 const currentListOfIds = carry.map((cItem) => cItem.id);
//                 if (currentListOfIds.indexOf(item.id) < 0) {
//                     carry.push(item);
//                 }

//                 return carry;
//             }, []);

//         if (response.data._Imagens.length === 0) {
//             thunkAPI.dispatch(
//                 setImages({
//                     images: [],
//                     page: page,
//                     maxPage: response.data.iTotalPagina,
//                     params,
//                 }),
//             );
//             UI.showInfoMessage(
//                 'Nenhuma peça encontrada para este filtro de busca.',
//             );
//             return;
//         }

//         if (page > 1) {
//             thunkAPI.dispatch(
//                 setImages({
//                     images: [...images, ...imagesResponse],
//                     page: page + 1,
//                     maxPage: response.data.iTotalPagina,
//                     params,
//                 }),
//             );
//             return;
//         } else if (page === 1) {
//             thunkAPI.dispatch(
//                 setImages({
//                     images: [...imagesResponse],
//                     page: page + 1,
//                     maxPage: response.data.iTotalPagina,
//                     params,
//                 }),
//             );
//             return;
//         }
//     }
//     if (response.status === 404) {
//         thunkAPI.dispatch(
//             setImages({
//                 images: [],
//                 page: page,
//                 maxPage: response.data.iTotalPagina,
//                 params,
//             }),
//         );
//         UI.showInfoMessage(
//             'Não foi possível encontrar um resultado para o filtro selecionado.',
//         );
//         return;
//     }
//     callback(null);