import React from 'react'
import { useNavigate } from 'react-router-dom'

import './styles.css'
import logo from '../../assets/logo.svg'

function Login() {
    const navigate = useNavigate()

    return (
        <div className='login-container'>
            <img alt='' src={logo} />

            <div className='login-form'>
                <text>E-mail</text>
                <input type='email' />

                <text>Senha</text>
                <input type='password' />
            </div>

            <div className='login-buttons'>
                <div className='login-button-login' onClick={() => navigate('/feed')}>Login</div>
                <text>Esqueci minha senha</text>
                <div className='login-button-create'>Criar um conta</div>
            </div>

        </div>
    )
}

export default Login