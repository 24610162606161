import axios from "axios";

const api = axios.create({
    baseURL: 'https://apimobip.leapstyle.me/api',
    // baseURL: 'http://20.51.255.252:6980/api',
    // baseURL: 'http://20.51.255.252:8981/api',
    headers: {
        'Authorization': 'Basic QWNjZXNzTGVhcFN0eWxlREVWQVBJOk1qQk1SV0Z3VTNSNVRHVlNiMk5yZEZOMGRXUnBiekl4'
    }
})

export default api;