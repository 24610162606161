import { BrowserRouter, Route, Routes } from 'react-router-dom';

import Feed from './feed'
import Login from './login'
import Product from './product';

export function Router() {
    return (
        <BrowserRouter>
            <Routes>
                <Route path='/' element={<Login />} />
                <Route path='/feed' element={<Feed />} />
                <Route path='/product/:idImage' element={<Product />} />
            </Routes>
        </BrowserRouter>
    )
}