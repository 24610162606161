import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import callApi from '../../services/api'

import StoreComp from "./components/store";
import ImageScreen from "./components/imageScreen";

function Product() {
    const navigate = useNavigate()
    const [loading, setLoading] = useState(true)
    const [screen, setScreen] = useState(0)
    const [productInfo, setProductInfo] = useState([])

    const { idImage } = useParams();

    const getImageId = () => {
        try {
            let config = {
                method: 'post',
                url: '/Imagem/GetImagemById',
                params: {
                    Id: idImage,
                    IdUsuario: 46,
                    join: true,
                },
            };

            callApi(config)
                .then((response) => {
                    if (response.status === 200)
                        setProductInfo(response.data)
                    setLoading(false)
                })
                .catch((error) => {
                    console.log(error)
                });
        } catch (err) {
            console.log('[Error]', err)
        }
    }

    const selectScreenPress = () => {
        if(screen > 0) {
            setScreen(0)
        } else {
            setScreen(1)
        }
    }

    useEffect(() => {
        if (idImage)
            getImageId()
    }, [idImage])

    return (
        <div style={{backgroundColor: '#2c2c2c', minHeight: '100vh'}}>
            {!loading &&
                screen === 1 ?
                <StoreComp productInfo={productInfo} selectScreenPress={selectScreenPress} />
                :
                <ImageScreen productInfo={productInfo} selectScreenPress={selectScreenPress} />
            }
        </div>
    )
}

export default Product;